import React, { ReactElement, ReactNode } from 'react'

type AuthContext = {
  isAuthenticated: boolean
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  isAuthenticationLoading: boolean
  setAuthenticationLoading: React.Dispatch<React.SetStateAction<boolean>>
  isAuthenticatedAgent: boolean
  setAuthenticatedAgent: React.Dispatch<React.SetStateAction<boolean>>
  isAuthenticationLoadingAgent: boolean
  setAuthenticationLoadingAgent: React.Dispatch<React.SetStateAction<boolean>>
}

export type AuthContextAuthenticated = (boolean) => void

export const AuthContext = React.createContext<AuthContext>({
  isAuthenticated: false,
  setAuthenticated: () => {},
  isAuthenticationLoading: false,
  setAuthenticationLoading: () => {},
  isAuthenticatedAgent: false,
  setAuthenticatedAgent: () => {},
  isAuthenticationLoadingAgent: false,
  setAuthenticationLoadingAgent: () => {},
})

/**
 * The initial value of `isAuthenticated` comes from the `authenticated`
 * prop which gets set by _app. We store that value in state and ignore
 * the prop from then on. The value can be changed by calling the
 * `setAuthenticated()` method in the context.
 */
export const AuthProvider = ({
  children,
  authenticated,
  authenticatedAgent,
}: {
  children: ReactNode
  authenticated: boolean
  authenticatedAgent: boolean
}): ReactElement => {
  const [isAuthenticated, setAuthenticated] = React.useState<boolean>(authenticated)
  const [isAuthenticationLoading, setAuthenticationLoading] = React.useState<boolean>(false)

  const [isAuthenticatedAgent, setAuthenticatedAgent] = React.useState<boolean>(authenticatedAgent)
  const [isAuthenticationLoadingAgent, setAuthenticationLoadingAgent] = React.useState<boolean>(
    false,
  )

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuthenticated,
        isAuthenticationLoading,
        setAuthenticationLoading,
        isAuthenticatedAgent,
        setAuthenticatedAgent,
        isAuthenticationLoadingAgent,
        setAuthenticationLoadingAgent,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export function useIsAuthenticated() {
  const context = useAuth()
  return context.isAuthenticated
}

export function useAuthenticationLoading() {
  const context = useAuth()
  return context.isAuthenticationLoading
}

export function useIsAuthenticatedAgent() {
  const context = useAuth()
  return context.isAuthenticatedAgent
}

export function useAuthenticationLoadingAgent() {
  const context = useAuth()
  return context.isAuthenticationLoadingAgent
}
